import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link as ScrollLink } from 'react-scroll'
import Link from 'next/link'
import { format } from 'date-fns'
import { OEDS } from '../atoms/Icons/oeds'
import { HideXS } from '../atoms/mediaQueries'
import { ClientOnlyPortal } from '../templates/ClientOnlyPortal'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: ${({ sticky }) => (sticky ? 'sticky' : 'static')};
  top: 0;
  z-index: ${(props) => props.theme.zIndex.overlay};

  ${({ theme }) => theme.mediaQueries.maxTabletWidth} {
    position: static;
  }
`
const Scroll = styled.div`
  height: calc(100vh - 28rem);
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    visibility: hidden;
  }
`

export const TopIcon = styled.div`
  padding: 4.2rem 0 8rem 4rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ theme }) => theme.mediaQueries.maxTabletWidth} {
    position: absolute;
    padding: 2rem;

    svg {
      width: 35px;
      height: 35px;
    }
  }
`

export const IconText = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.25;
  color: ${({ theme }) => theme.colors.heading};
  height: 4rem;
  width: 16.5rem;
  transform: translateY(0);
  margin-left: 10rem;
  position: absolute;
  top: 4.2rem;

  ${({ theme }) => theme.mediaQueries.maxTabletWidth} {
    font-size: 1.4rem;
    top: 2rem;
    margin-left: 7.2rem;
    max-width: 14.4rem;
  }
`

const NavItems = styled.div`
  flex: 1;
  padding-left: 4rem;
  top: 17.9rem;
  width: 16rem;
`

const NavGroupTitle = styled.div`
  opacity: 0.6;
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 1rem;
  font-family: ${({ theme }) => theme.fonts.default};
`

const NavGroup = styled.div`
  margin-bottom: 3rem;
  padding-right: 1rem;
`

const SanitizedLink = ({ active, ...props }) => <ScrollLink {...props} />

const NavItem = styled(SanitizedLink)`
  font-family: ${({ theme }) => theme.fonts.default};
  font-size: 1.4rem;
  line-height: normal;
  margin-block-start: 1.4rem;
  margin-block-end: 1.4rem;
  color: ${({ active, theme }) => (active ? theme.colors.heading : theme.colors.text)};
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;

  ${({ active, theme }) =>
    active &&
    `
    font-weight: 500;
    -webkit-text-stroke: 0.25px;

    &:before {
      width: 30px;
      height: 4px;
      background-color: ${theme.colors.sideNavGreen};
      content: ' ';
      display: block;
      margin-left: -40px;
      margin-right: 10px;
      position: absolute;
      top: 6px;
    }
  `}
`

const LastUpdatedText = styled.div`
  opacity: 0.5;
  font-family: ${({ theme }) => theme.fonts.default};
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.text};
  margin-left: 4rem;
  margin-top: 1.5rem;
  max-width: 104px;
`

export const SideNavigation = ({ navigationItems = [], lastUpdated = '' }) => {
  const [activeId, setActiveId] = useState(navigationItems[0]?.items[0])

  useEffect(() => {
    if (navigationItems.length) {
      setActiveId(navigationItems[0]?.items[0])
    }
  }, [])

  const lastUpdatedFormatted = lastUpdated && format(new Date(lastUpdated), "do 'of' MMM',' y")

  const hasNavigationItems = !!navigationItems.length

  return (
    <ClientOnlyPortal selector="#side-navigation">
      <Link href="/">
        <IconText> Open Employment Data Standards</IconText>
      </Link>
      <Content sticky={hasNavigationItems}>
        <Link href="/">
          <TopIcon>
            <OEDS />
          </TopIcon>
        </Link>
        <HideXS>
          <Scroll>
            <NavItems>
              {navigationItems.map(({ title, items }) => (
                <NavGroup key={title}>
                  <NavGroupTitle>{title?.toUpperCase()}</NavGroupTitle>
                  {items.map((item) => (
                    <NavItem
                      key={item}
                      activeClass="active"
                      active={item === activeId}
                      to={item}
                      spy
                      smooth
                      offset={-150}
                      duration={500}
                      onSetActive={() => {
                        setActiveId(item)
                      }}
                    >
                      {item}
                    </NavItem>
                  ))}
                </NavGroup>
              ))}
            </NavItems>
            {lastUpdatedFormatted && <LastUpdatedText>{`Last updated on ${lastUpdatedFormatted}`}</LastUpdatedText>}
          </Scroll>
        </HideXS>
      </Content>
    </ClientOnlyPortal>
  )
}
