import React from 'react'

export const OEDS = ({ width = 40, height = 40 }) => (
  <svg width={width} height={height} fill="none" viewBox="0 0 40 40">
    <path
      fill="#081377"
      fillRule="evenodd"
      d="M36.002 8C32.352 3.142 26.543 0 20 0 13.457 0 7.647 3.142 3.999 8H36zM.4 24c-.262-1.292-.4-2.63-.4-4s.138-2.708.4-4h39.2c.262 1.292.4 2.63.4 4s-.138 2.708-.4 4H.4zm35.602 8c-3.65 4.858-9.459 8-16.002 8-6.543 0-12.353-3.142-16.001-8H36z"
      clipRule="evenodd"
    />
  </svg>
)
